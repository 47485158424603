import React, {useEffect} from 'react';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';

const AdminPage: React.FC<{}> =() => {
  useEffect(() => {
    navigate(Routes.materials);
  }, []);
  return null;
};

export default AdminPage;
